import DestinationPage from './Feature/Components/DestinationPage/DestinationPage.PAPI';
import './App.css';
import LandingGrid from './Feature/Components/LandingPage/LandingPage.PAPI';
import NavBar from './Feature/Components/NavBar/NavBar.PAPI';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { versionMessage } from './versioning';

// <-- Import End -->

function App() {
  return (
    <div className='App'>
      {versionMessage}
      <Router>
        <NavBar></NavBar>
        <Switch>
          <Route exact path='/' component={LandingGrid}></Route>
          <Route path='/destinations' component={DestinationPage}></Route>
        </Switch>
      </Router>
    </div>
  );
}

export default App;
