/* This example requires Tailwind CSS v2.0+ */
import './Styles/Background.css'
import {
	GiftIcon,
	GlobeIcon,
	LightBulbIcon,
	SparklesIcon,
	CheckCircleIcon,
	StarIcon
      } from '@heroicons/react/outline'

      const features = [
	{ name: 'All Inclusives', icon: SparklesIcon, description: 'Receive the hottest all-inclusive destinations ' },
	{ name: 'Top Shelf Brands', icon: CheckCircleIcon, description: 'Get the top resorts in your desired destination.' },
	{ name: 'Best In Service', icon: GiftIcon, description: 'Be pampered from begining to end. Let us take care of you!' },
	{ name: 'Simple', icon: LightBulbIcon, description: 'Your just a few taps away from your getaway.' },
	{ name: 'Premium Accomodations', icon: StarIcon, description: 'Spend a few nights in that Villa you\'ve always dreamed about.' },
	{ name: 'Curated Experience', icon: GlobeIcon, description: 'We\'ve done the research and found out what guests are looking for.' },
      ]

      export default function LandingGrid() {
	return (
	  <div style={{height:'100%'}} className="background relative bg-white py-16 sm:py-24 lg:py-32">
	    <div className="bg-opacity-10 mx-auto max-w-md px-4 text-center sm:max-w-3xl sm:px-6 lg:px-8 lg:max-w-7xl">
	      <h2 className="text-base font-semibold tracking-wider text-green-600 uppercase">Monster</h2>
	      <p className="mt-2 text-3xl font-extrabold text-gray-900 tracking-tight sm:text-4xl">
		A better way to travel.
	      </p>
	      <p className="mt-5 max-w-prose mx-auto text-xl text-gray-500">
	      Want to go on vacation but don’t know where? We make it easy for you. We find the best luxury vacations and put them all in one place. All you have to do is book your trip and we take care of everything else! You deserve a break, so why not treat yourself with something amazing? The best part? It's already paid for!
	      </p>
	      <div className="mt-12">
		<div className="grid grid-cols-1 gap-8 sm:grid-cols-2 lg:grid-cols-3">
		  {features.map((feature) => (
		    <div key={feature.name} className="pt-6">
		      <div className="flow-root bg-gray-50 rounded-lg px-6 pb-8">
			<div className="-mt-6">
			  <div>
			    <span className="inline-flex items-center justify-center p-3 bg-green-600 rounded-md shadow-lg">
			      <feature.icon className="h-6 w-6 text-white" aria-hidden="true" />
			    </span>
			  </div>
			  <h3 className="mt-8 text-lg font-medium text-gray-900 tracking-tight">{feature.name}</h3>
			  <p className="mt-5 text-base text-gray-500">
			    {feature.description}
			  </p>
			</div>
		      </div>
		    </div>
		  ))}
		</div>
	      </div>
	    </div>
	  </div>
	)
      }
      