/*
  This example requires Tailwind CSS v2.0+ 
  
  This example requires some changes to your config:
  
  ```
  // tailwind.config.js
  module.exports = {
    // ...
    plugins: [
      // ...
      require('@tailwindcss/aspect-ratio'),
    ],
  }
  ```
*/
const products = [
  {
    id: 1,
    name: 'Punta Cana ',
    href: '#',
    price: '$0',
    description:
      'Enjoy a tropical paradise nesled in one of the biggest harbors in the country.',
    options: '2 Room types available',
    imageSrc:
      'https://www.flytap.com/-/media/Flytap/new-tap-pages/destinations/north-america/dominican-republic/punta-cana/Images-Landing-Page-Destinations-punta-cana/PUJ_PagDestino_JUN2021_HeroeBannerMobile_1024x553.jpg',
    imageAlt: 'Punta Cana Dr.',
  },
  {
    id: 2,
    name: 'Branson',
    href: '#',
    price: '$0',
    description: 'The entertainment capital of the midwest',
    options: '3 Room Types Available',
    imageSrc:
      'https://i0.wp.com/bransontravel.com/wp-content/uploads/2020/09/aquarium.jpg',
    imageAlt: 'Branson Mo',
  },
  // More products...
];

export default function DestinationPage() {
  return (
    <div className='bg-white'>
      <div className='max-w-2xl mx-auto py-16 px-4 sm:py-24 sm:px-6 lg:max-w-7xl lg:px-8'>
        <h2 className='sr-only'>Products</h2>

        <div className='grid grid-cols-1 gap-y-4 sm:grid-cols-2 sm:gap-x-6 sm:gap-y-10 lg:grid-cols-3 lg:gap-x-8'>
          {products.map((product) => (
            <div
              key={product.id}
              className='group relative bg-white border border-gray-200 rounded-lg flex flex-col overflow-hidden'>
              <div className='aspect-w-3 aspect-h-4 bg-gray-200 group-hover:opacity-75 sm:aspect-none sm:h-96'>
                <img
                  src={product.imageSrc}
                  alt={product.imageAlt}
                  className='w-full h-full object-center object-cover sm:w-full sm:h-full'
                />
              </div>
              <div className='flex-1 p-4 space-y-2 flex flex-col'>
                <h3 className='text-sm font-medium text-gray-900'>
                  <a href={product.href}>
                    <span aria-hidden='true' className='absolute inset-0' />
                    {product.name}
                  </a>
                </h3>
                <p className='text-sm text-gray-500'>{product.description}</p>
                <div className='flex-1 flex flex-col justify-end'>
                  <p className='text-sm italic text-gray-500'>{product.options}</p>
                  <p className='text-base font-medium text-gray-900'>
                    {product.price}
                  </p>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
