/* This example requires Tailwind CSS v2.0+ */
import {useLocation} from 'react-router-dom'  

// import { useMemo } from "react"
import {Link} from 'react-router-dom'
const navigation = [
  { name: 'Website', href: '#' },
  { name: 'Facebook', href: '#' },
  { name: 'Twitter', href: '#' },
  { name: 'About', href: '#' },
]

export default function NavBar() {
  const location = useLocation()
  const path = location.pathname
  let button;
  if (path === '/') {
    button =  <Link    data-test="start"             className="font-extrabold animate-bounce inline-block bg-green-500 py-2 px-4 border border-transparent rounded-md text-base text-white hover:bg-opacity-75" to="destinations">
Start!
            </Link>
  } else {
    button = '' }
  return (
    <header className="bg-green-600">
      <nav className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8" aria-label="Top">
        <div className="w-full py-6 flex items-center justify-between border-b border-green-500 lg:border-none">
          <div className="flex items-center">
              <span className="sr-only">Monster</span>
              <img
                className="h-12 w-auto"
                src="https://i.ibb.co/374R7dv/monster-copy.png"
                alt=""
              />
            <div className="hidden ml-10 space-x-8 lg:block">
              {navigation.map((link) => (
                <a key={link.name} href={link.href} className="text-base font-medium text-white hover:text-green-50">
                  {link.name}
                </a>
              ))}
            </div>
          </div>
          <div className="ml-10 space-x-4">
          {button}
            <a
              href="#"
              className=" inline-block bg-white py-2 px-4 border border-transparent rounded-md text-base font-medium text-green-600 hover:bg-green-50"
            >
              Like us
            </a>
          </div>
        </div>
        <div className="py-4 flex flex-wrap justify-center space-x-6 lg:hidden">
          {navigation.map((link) => (
            <a key={link.name} href={link.href} className="text-base font-medium text-white hover:text-green-50">
              {link.name}
            </a>
          ))}
        </div>
      </nav>
    </header>
  )
}
